import React, { useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";

import { HTMLContent } from "../components/Content";
import AboutUs from "../pages/about-us";

const AboutUsTemplate = ({ data }) => {
  const { allMarkdownRemark: nodes } = data;

  return (
    <Layout>
      <AboutUs />
    </Layout>
  );
};

// ArticleTemplate.propTypes = {
//     data: PropTypes.shape({
//         markdownRemark: PropTypes.object,
//     }),
// };

export default AboutUsTemplate;

export const templateQuery = graphql`
  query AboutUsTemplateQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "about-us-page" } } }
    ) {
      nodes {
        id
        frontmatter {
          templateKey
          description
          people {
            name
            avatar
            description
            facebook
            instagram
            otherLinks {
              linkName
              link
            }
          }
        }
      }
    }
  }
`;
